<template>
	<PremiumClub v-if="brandName === 'PremiumClub'" :goToLoginPage="goToLoginPage" :submit="submit" :rules="rules" :passSent="passSent" :isFormValidProp="isFormValid"/>
	<Default v-else :goToLoginPage="goToLoginPage" :submit="submit" :rules="rules" :passSent="passSent" :isFormValidProp="isFormValid"/>
</template>

<script>
import ApiService from '../../services/ApiService.js';
import { mapGetters } from 'vuex';

export default {
	components: {
		Default: () => import('./components/Default/index.vue'),
		PremiumClub: () => import('./components/PremiumClub/index.vue')
	},
	data() {
		return {
			passSent: false,
			isFormValid: false,
			rules: {
				required: (value) => !!value || this.$t('global.required'),
				email: (value) => {
					const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
					return pattern.test(value) || this.$t('global.invalidEmail');
				}
			}
		};
	},
	computed: {
        ...mapGetters(['brandName'])
	},
	methods: {
		goToLoginPage() {
			const queryParams = window.location.search;
			this.$router.push(`/Login${queryParams}`);
		},
		submit(email) {
			ApiService.clientsApi.generateNewPasswordByMail(email, this.brandName);
			this.passSent = true;
		}
	}
};
</script>
<style scoped>
.default-class {
	color: white !important;
}
</style>
